import React from 'react';
import ReactGA4 from 'react-ga4';

import 'antd/dist/antd.css';
import './styles/index.css';

import './styles/App.css';
import './styles/LightMode.css';
import './styles/Navbar.css';

import './components/global/styles/gstyles.scss';
import './components/global/styles/gtable.css';

import { AppProvider, useToast } from '@alpine-iq/ui-library';
import '@alpine-iq/ui-library/styles/main.css';

import Routes from './routes';
import { utils } from './helpers';

export default () => {
	React.useEffect(() => {
		if (!(window as any).GA_INITIALIZED) {
			ReactGA4.initialize('G-PPKJCEKLT1');
			(window as any).GA_INITIALIZED = true;
		}

		ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });
	}, []);

	return (
		<>
			<AppProvider>
				<Toaster />
				<Routes />
			</AppProvider>
		</>
	);
};

const Toaster = () => {
	const toaster = useToast();
	utils.toaster = toaster;
	return null;
};
