import { UserSettings } from '@alpine-iq/shared';
import { fetchAPI, FetchOptions } from '.';

const getStoreID = (storeID?: string) => (storeID && `${storeID}` !== '-1' ? `?storeID=${storeID}` : '');

export function getUserSettings(storeID?: string, options?: FetchOptions): Promise<Partial<UserSettings>> {
	return fetchAPI(`/user/settings/:uid${getStoreID(storeID)}`, { ...options });
}

export function updateUserSettings(settings: Partial<UserSettings>, storeID?: string, options?: FetchOptions): Promise<Partial<UserSettings>> {
	return fetchAPI(`/user/settings/:uid${getStoreID(storeID)}`, { method: 'PUT', payload: settings, ...options });
}

export function deleteUserSettings(storeID?: string, options?: FetchOptions): Promise<void> {
	return fetchAPI(`/user/settings/:uid${getStoreID(storeID)}`, { method: 'DELETE', ...options });
}
