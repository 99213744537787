import { generateRandomPhoneNumber, utils } from '@/helpers';
import { FetchOptions, fetchAPI } from '.';

export type TelnyxUser = {
	id: string;
	mid?: string;
	numbers?: TelnyxNumber[];
	removed?: TelnyxNumber[];
	created?: number;
	updated?: number;
};

export type TelnyxNumber = {
	id: string;
	phone_number: string;
	cost_information?: CostInformation;
	record_type?: string;
	features?: Feature[];
	region_information?: RegionInformation[];
	tcmpID?: string;
	removeTS: number;
	delivery?: PhoneDeliveryRate;
};

export type TelnyxAvailable = {
	vanity_format?: any;
	cost_information: CostInformation;
	phone_number: string;
	record_type: string;
	features: Feature[];
	region_information: RegionInformation[];
	best_effort: boolean;
	reservable: boolean;
	quickship: boolean;
};

type CostInformation = {
	upfront_cost: string;
	monthly_cost: string;
	currency: string;
};

type PhoneDeliveryRate = {
	phone: string;
	messagesDelivered: number;
	messagesFailed: number;
	failedPercent: number;
};

type Feature = { name: string };
type RegionInformation = { region_name: string; region_type: string };

export type TelnyxNumberType = TelnyxNumber | TelnyxAvailable;

// marketing.GET("/userTelnyx/:uid", s.getUserTelnyx)
// marketing.GET("/telnyx/search/:code", s.searchPhones)
// marketing.PUT("/telnyx/buy/:uid/:number", s.manualBuyPhone)
// marketing.DELETE("/telnyx/:uid/:phone", s.deleteTelnyxNumber)

export function getTelnyxUser(options?: FetchOptions): Promise<TelnyxUser> {
	if (utils.isLocal()) {
		return Promise.resolve({
			id: '123',
			mid: '123',
			removed: [],
			updated: 1718851200,
			created: 1718851200,
			numbers: Array.from(
				{ length: 100 },
				(_, i) =>
					({
						phone_number: generateRandomPhoneNumber(),
						record_type: 'local',
						features: [{ name: 'SMS' }],
						cost_information: { upfront_cost: '0', monthly_cost: '1', currency: 'USD' },
					}) as TelnyxNumber,
			),
		});
	}
	return fetchAPI('/userTelnyx/:uid?includeFailure=true', { method: 'GET', ...options });
}

export function searchPhones(code: string, type?: string, options?: FetchOptions): Promise<TelnyxAvailable[]> {
	if (utils.isLocal()) {
		return Promise.resolve(
			Array.from(
				{ length: 100 },
				(_, i) =>
					({
						phone_number: generateRandomPhoneNumber(),
						record_type: 'local',
						features: [{ name: 'SMS' }],
						cost_information: { upfront_cost: '0', monthly_cost: '1', currency: 'USD' },
					}) as TelnyxAvailable,
			),
		);
	}
	return fetchAPI(`/telnyx/search/:uid/${code}?typ=${type}`, { method: 'GET', ...options });
}

export function manualBuyPhone(number: string, options?: FetchOptions): Promise<void> {
	return fetchAPI(`/telnyx/buy/:uid/${number}?skip=true`, { method: 'PUT', ...options });
}

export function deleteTelnyxNumber(phone: string, options?: FetchOptions): Promise<void> {
	return fetchAPI(`/telnyx/:uid/${phone}`, { method: 'DELETE', ...options });
}
